import React, {useState} from 'react';
// import '../assets/styles/Landing.css';
import Switch1 from '../assets/images/switcher-potenciometro.jpg';
import Switch2 from '../assets/images/switcher-opciones.jpg';
import Switch3 from '../assets/images/switcher-iluminacion-general.jpg';
import Img1 from '../assets/images/mainview-slide2.jpg';

const App = () => {
  const [spot, setSpot] = useState(0);
  const [ambient, setAmbient] = useState(0);

  return (
    <div className="page">
      <header className="header">
        <a href="/" className="logo">
          Infinity
        </a>
      </header>
      <div className="atf">
        <div className={'room' + (ambient ? ' ambient-on' : '') + (spot ? ' spot-on' : '')}>
          <div className="wall">
            <div className="control">
              <div
                className={'switch ambient ' + (ambient ? 'on' : '')}
                onClick={() => setAmbient(ambient === 1 ? 0 : 1)}
              />
              <div className={'switch spot ' + (spot ? 'on' : '')} onClick={() => setSpot(spot === 1 ? 0 : 1)} />
            </div>
          </div>
          <div className="lights">
            <div className={'light spot ' + (spot ? 'on' : '')} />
            <div className={'light ambient ' + (ambient ? 'on' : '')} />
          </div>
          <div className="cta">
            <h1>Tu casa con una experiencia unica</h1>
            <h2>¿Te gustaría una demo personalizada?</h2>
            <a href="#contacto" className="button">
              Contactanos
            </a>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="wrapper margin-bottom">
          <h2>Iluminacion con control total</h2>
          <p>
            Acceso multiple desde cualquier habitacion de tu casa a cualquier habitacion de tu casa. Con un Quick Hub
            puedes controlar la iluminacion, ambientacion, el sonido y video de cualquier espacio.
            <br />
            <br />
            <span className="strong">¿Quieres descubrir todo lo que Quick Hub puede hacer?</span>
          </p>
          <button className="button primary">Descubrir</button>
        </div>

        <div className="slider">
          <div className="slide">
            <img src={Switch1} alt="Quick Hub" />
          </div>
          <div className="slide">
            <img src={Switch2} alt="Quick Hub" />
          </div>
          <div className="slide">
            <img src={Switch3} alt="Quick Hub" />
          </div>
        </div>
      </section>
      <section className="section">
        <div className="wrapper margin-bottom">
          <h2>Tu hogar, un integrante mas de tu familia</h2>
          <p>
            Omni Hub aprende a reconocer tu rostro y a ajustar la configuracion de tu hogar según tus gustos y
            preferencias. Configura notificaciones, timbres, alarmas sonoras y visuales. Establece preferencias de
            ambiente, temperatura y multimedia, y mucho mas.
            <br />
            <br />
            <span className="strong">¿Quieres descubrir todo lo que Omin Hub puede hacer?</span>
          </p>
          <button className="button primary">Descubrir</button>
        </div>

        <div className="image-center">
          <img src={Img1} alt="Omni Hub" />
        </div>
      </section>
      <section className="section wrapper">
        <h2>Gestion de energia inteligente.</h2>
        <p>
          Omni Hub y Quick Hub aprenden en conjunto sobre tu consumo y administracion de energia para poder ahorrar
          automaticamente en los momentos mas oportunos.
          <br />
          <br />
          <span className="strong">¿Quieres descubrir todo lo que tu casa inteligente puede hacer?</span>
        </p>
      </section>
      <section className="section wrapper center">
        <h2>Aún hay mucho mas que tu casa puede hacer por vos y tu familia</h2>
        <p>
          <span className="strong">Solicita una demo para Re-descubir tu casa.</span>
        </p>

        <form>
          <div className="input">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" />
          </div>
          <div className="input">
            <label htmlFor="name">Nombre</label>
            <input type="text" name="name" id="name" />
          </div>
          <div className="input">
            <input type="checkbox" name="subscribe" />
            <label htmlFor="subscribe">Recibir actualizaciones sobre productos</label>
          </div>
          <button className="button primary">Solicitar Demo</button>
        </form>
      </section>
      <footer className="footer">
        <p>Copyright © {new Date().getFullYear()}, Infinity. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default App;
